import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import Endpoints from "../constants/Endpoints";
import { axiosSendbirdInstance } from "./api";

interface CreateGroupChannelFormdata {
  user_ids: ["listo-bot"];
  name: string;
  custom_type: "supplier";
  data: string;
  is_public: true;
}

interface DeleteGroupChannelFormdata {
  channel_url: string;
}

interface FreezeGroupChannelFormdata {
  channel_url: string;
}

interface CreateCustomMessageFormdata {
  channel_url?: string;
  channel_type?: "open_channels" | "group_channels";
  message_type: "MESG";
  user_id: string;
  message: string;
  custom_type?: string;
  mark_as_read: boolean;
  is_silent: boolean;
  send_push: boolean;
}

interface CreateGroupChannelMetadataFormData {
  channel_url: string;
  metadata: {
    restaurant_id: string,
    supplier_id: string,
  };
  include_ts?: boolean;
}

interface CreateGroupChannelMetadataResponse {
  metadata: {
    restaurant_id: number,
    supplier_id: string,
  };
  include_ts: boolean;
}

const _createGroupChannel = async (
  formData: CreateGroupChannelFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosSendbirdInstance.post(
        `${Endpoints.SB_CREATE_GROUP_CHANNEL}`,
        formData
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useCreateGroupChannel = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (createGroupChannelFormData) =>
      _createGroupChannel(createGroupChannelFormData),
    options
  );
};

const _deleteGroupChannel = async (
  formData: DeleteGroupChannelFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosSendbirdInstance.delete(
        `${Endpoints.SB_CREATE_GROUP_CHANNEL}/${formData.channel_url}`
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useDeleteGroupChannel = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (deleteGroupChannelFormData: DeleteGroupChannelFormdata) =>
      _deleteGroupChannel(deleteGroupChannelFormData),
    options
  );
};

const _freezeGroupChannel = async (
  formData: FreezeGroupChannelFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosSendbirdInstance.put(
        `${Endpoints.SB_CREATE_GROUP_CHANNEL}/${formData.channel_url}/freeze`,
        {
          freeze: true,
        }
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useFreezeGroupChannel = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (freezeGroupChannelFormData: FreezeGroupChannelFormdata) =>
      _freezeGroupChannel(freezeGroupChannelFormData),
    options
  );
};

const _createCustomMessage = async (
  formData: CreateCustomMessageFormdata
): Promise<any> => {
  const { channel_type, channel_url } = formData;
  delete formData.channel_type;
  delete formData.channel_url;

  try {
    const response: any = (
      await axiosSendbirdInstance.post(
        `${Endpoints.SB_CREATE_CUSTOM_MESSAGE}`
          .replace("{channel_type}", channel_type)
          .replace("{channel_url}", channel_url),
        formData
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useCreateCustomMessage = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (createCustomMessageFormData: CreateCustomMessageFormdata) =>
      _createCustomMessage(createCustomMessageFormData),
    options
  );
};

const _createGroupChannelMetadata = async (
  formData: CreateGroupChannelMetadataFormData
): Promise<any> => {
  const { channel_url, metadata } = formData;

  try {
    const response: CreateGroupChannelMetadataResponse = (
      await axiosSendbirdInstance.post(
        `${Endpoints.SB_CREATE_GROUP_CHANNEL_METADATA}`.replace(
          "{channel_url}",
          channel_url
        ),
        {
          metadata,
        }
      )
    ).data;

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useCreateGroupChannelMetadata = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (groupChannelFormData) => _createGroupChannelMetadata(groupChannelFormData),
    options
  );
};

const _updateGroupChannelMetadata = async (
  formData: CreateGroupChannelMetadataFormData
): Promise<any> => {
  const { channel_url, metadata } = formData;

  try {
    const response: CreateGroupChannelMetadataResponse = (
      await axiosSendbirdInstance.put(
        `${Endpoints.SB_CREATE_GROUP_CHANNEL_METADATA}`.replace(
          "{channel_url}",
          channel_url
        ),
        {
          metadata,
        }
      )
    ).data;

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useUpdateGroupChannelMetadata = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (groupChannelFormData) => _updateGroupChannelMetadata(groupChannelFormData),
    options
  );
};
