import React, { useState } from "react";
import { find } from "lodash";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Confirm,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton as DefaultSaveButton,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useRecordContext,
  useRefresh,
  Pagination,
} from "react-admin";
import { Box, Button } from "@material-ui/core";
import RestaurantSupplierShow from "./restaurant-supplier/RestaurantSupplierShow";
import { toast } from "react-toastify";
import {
  useCreateGroupChannelMetadata,
  useUpdateGroupChannelMetadata,
} from "../services/sendbird";
import { usePatchBulkRestaurantsSuppliers } from "../services/suppliers";
import { currentEnvironment } from "./Supplier";

const RestaurantFilter = [
  <TextInput source="name" label="Nombre del restaurante" resettable />,
  <DateInput source="RestaurantsByCustomerByDate.date.eq" label="Date" />,
  <TextInput
    source="RestaurantsByRepresentativeByDate.accountRepresentativeID"
    label="Account representative id"
    resettable
  />,
  <DateInput source="RestaurantsByRepresentativeByDate.date.eq" label="Date" />,
  <ReferenceInput
    source="RestaurantsByProduct.productID"
    reference="products"
    label="Product"
    filterToQuery={(searchText) => ({
      productsByName: { name: searchText },
    })}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

const postFilters = [<TextInput label="Buscar" source="search" alwaysOn />];

const ContactNameTextField = ({ source }) => {
  const record = useRecordContext();
  if (record && record[source]) {
    const user = find(
      record[source],
      (user) => user.role === "super_admin" || user.role === "admin"
    );
    if (user && (user.first_name || user.last_name)) {
      return <span>{`${user.first_name} ${user.last_name}`}</span>;
    }
    return <></>;
  } else {
    return <></>;
  }
};

const ContactPhoneTextField = ({ source }) => {
  const record = useRecordContext();
  if (record && record[source]) {
    const user = find(
      record[source],
      (user) => user.role === "super_admin" || user.role === "admin"
    );
    if (user) {
      return <span>{user.phone_number}</span>;
    }
    return <></>;
  } else {
    return <></>;
  }
};

export const RestaurantList = (props) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="name" label="Nombre" sortable={false} />
        <ContactNameTextField
          source="__restaurant_users__"
          label="Nombre de contacto"
        />
        <ContactPhoneTextField
          source="__restaurant_users__"
          label="Teléfono de contacto"
        />
        <BooleanField source="is_available_for_chat" label="Chat disponible" />
        <BooleanField source="has_order" label="Tiene pedidos" />
        <DateField
          source="created_at"
          label="Creado el"
          sortable={false}
          defaultValue="-"
        />
        <ShowButton label="Ver" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <TextField
      source="name"
      label="Nombre"
      style={{ flex: 1, fontSize: 20, fontWeight: "bold" }}
    />
    <Button color="primary" onClick={() => null}>
      Catálogo master
    </Button>
  </TopToolbar>
);

const GetTitle = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return <span> - {record.name}</span>;
};

export const RestaurantShow = (props) => {
  const refresh = useRefresh();
  const { mutate: createMetadata } = useCreateGroupChannelMetadata();
  const { mutate: patchBulkRelation } = usePatchBulkRestaurantsSuppliers();
  const { mutate: updateMetadata } = useUpdateGroupChannelMetadata();

  const handleBulkPriceChange = (tableIds: string[], canSee: boolean) => {
    const ids = tableIds.map((tableId) => ({
      supplier_id: tableId.split("|")[0],
      restaurant_id: tableId.split("|")[1],
      can_see_products_prices: canSee,
    }));
    patchBulkRelation(ids, {
      onSuccess: () => {
        toast.success("Restaurantes actualizados exitosamente");
        refresh();
      },
    });
  };

  const CanSeePricesBulkButton = ({ label, selectedIds, canSee }) => {
    return (
      <button onClick={() => handleBulkPriceChange(selectedIds, canSee)}>
        {label}
      </button>
    );
  };

  const DisableCanSeePricesBulkButton = ({ label, selectedIds, canSee }) => {
    return (
      <button onClick={() => handleBulkPriceChange(selectedIds, canSee)}>
        {label}
      </button>
    );
  };

  const handleFixMetadataAction = (tableIds) => {
    const envForMetadata =
      currentEnvironment() === "production" ? "prod" : currentEnvironment();

    const ids = tableIds.map((tableId) => ({
      supplier_id: `${tableId.split("|")[0]}${envForMetadata}`,
      restaurant_id: `${tableId.split("|")[1]}${envForMetadata}`,
      channel_url: tableId.split("|")[2],
    }));
    for (let i = 0; i < ids.length; i++) {
      const { supplier_id, restaurant_id, channel_url } = ids[i];
      setTimeout(() => {
        createMetadata(
          {
            channel_url,
            metadata: {
              restaurant_id,
              supplier_id,
              env: envForMetadata,
            },
          },
          {
            onSuccess: () => {
              if (i === ids.length - 1) {
                toast.success("Relación actualizada exitosamente");
                refresh();
              }
            },
            onError: () => {
              updateMetadata(
                {
                  channel_url,
                  metadata: {
                    restaurant_id,
                    supplier_id,
                    env: envForMetadata,
                  },
                },
                {
                  onSuccess: () => {
                    if (i === ids.length - 1) {
                      toast.success("Relación actualizada exitosamente");
                      refresh();
                    }
                  },
                }
              );
            },
          }
        );
      }, i * 1000);
    }
  };

  const FixMetadataButton = ({ label, selectedIds }) => {
    return (
      <button onClick={() => handleFixMetadataAction(selectedIds)}>
        {label}
      </button>
    );
  };

  const ConnectBulkActionButtons = (props) => {
    return (
      <>
        <CanSeePricesBulkButton
          label="Habilitar precios"
          {...props}
          canSee={true}
        />
        <DisableCanSeePricesBulkButton
          label="Deshabilitar precios"
          {...props}
          canSee={false}
        />
        <FixMetadataButton label="Actualizar metadata" {...props} />
      </>
    );
  };

  return (
    <Show actions={<PostShowActions />} {...props} title={"Restaurante"}>
      <TabbedShowLayout
        {...props}
        tabs={<TabbedShowLayoutTabs variant="fullWidth" scrollButtons="auto" />}
      >
        <Tab label="Proveedores" role="tab">
          <span>Proveedores</span>
          <ReferenceManyField
            reference="restaurant-supplier"
            target="id"
            label="Órdenes recientes"
            pagination={<Pagination />}
          >
            <Datagrid
              empty={<p>No existen proveedores para este restaurante</p>}
              rowClick="expand"
              expand={<RestaurantSupplierShow refresh={refresh} />}
              bulkActionButtons={<ConnectBulkActionButtons />}
            >
              <TextField source="__supplier__.name" label="Proveedor" />
              <TextField source="__point_of_contact__.email" label="Correo" />
              <TextField
                source="__point_of_contact__.phone_number"
                label="Teléfono"
              />
              <TextField
                source="__point_of_contact__.first_name"
                label="Nombre"
              />
              <TextField
                source="__point_of_contact__.last_name"
                label="Apellido"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Chat" path="chat" disabled>
          <span>Chat</span>
        </Tab>
        <Tab label="Catálogos" path="catalogs" disabled>
          <List
            {...props}
            hasCreate={false}
            filters={RestaurantFilter}
            title={<GetTitle />}
          >
            <Datagrid>
              <TextField label="Restaurante" source="name" />
              <ReferenceField
                label="Proveedor"
                reference="catalogs"
                source="id"
              >
                <TextField source="provider" />
              </ReferenceField>
              <ReferenceField
                label="Catálogo Master"
                reference="catalogs"
                source="id"
              >
                <BooleanField source="masterCatalog" />
              </ReferenceField>
              <ReferenceField
                label="Ingresado"
                reference="catalogs"
                source="id"
              >
                <TextField source="addMethod" />
              </ReferenceField>
              <ReferenceField
                label="Items en lista de productos"
                reference="catalogs"
                source="id"
              >
                <NumberField source="productsNumber" />
              </ReferenceField>
              <ReferenceField
                label="Fecha de creación"
                reference="catalogs"
                source="id"
              >
                <DateField
                  source="createdAt"
                  locales="es-ES"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }}
                />
              </ReferenceField>
              <ShowButton label="Ver" />
              <EditButton label="Editar" />
            </Datagrid>
          </List>
        </Tab>
        <Tab label="Equipos" path="teams" disabled>
          <span>Equipos</span>
        </Tab>
        <Tab label="Sedes" path="headquarters" disabled>
          <span>Sedes</span>
        </Tab>
        <Tab label="Órdenes" path="orders" disabled>
          <span>Órdenes</span>
        </Tab>
        <Tab label="Invitaciones" path="invitations" disabled></Tab>
        <Tab label="Información" path="information">
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Box style={{ flex: 1, placeContent: "flex-start" }}>
              <span>Información</span>
              <SimpleShowLayout>
                <TextField source="name" label="Nombre" />
                <TextField source="address" label="Ubicación" />
                <TextField source="country" label="País" />
                <TextField source="mainContact" label="Contacto principal" />
                <TextField source="email" label="Email principal" />
                <TextField source="phone" label="Teléfono principal" />
                <TextField source="providers" label="Nro. de proveedores" />
                <TextField
                  source="products"
                  label="Lista de productos asociados"
                  emptyText="Sin productos asociados"
                />
                <TextField source="orders" label="Órdenes realizadas" />
                <DateField
                  label="Próxima orden"
                  source="nextOrder"
                  locales="es-ES"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }}
                />
                <DateField
                  label="Fecha de ingreso"
                  source="createdAt"
                  locales="es-ES"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }}
                />
              </SimpleShowLayout>
            </Box>
          </Box>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const validateCustomer = [required()];

export const RestaurantEdit = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteRestaurant = () => {};

  const handleDeleteRestaurantDialog = () => {
    setIsOpen(true);
  };

  const handleDeleteRestaurantDialogClose = () => {
    setIsOpen(false);
  };

  const RemoveRestaurantButton = ({ label }) => {
    return <button onClick={handleDeleteRestaurantDialog}>{label}</button>;
  };

  const CustomToolbar = (props) => (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <DefaultSaveButton label={"Guardar"} />
      <RemoveRestaurantButton label={"ELIMINAR"} />
    </Toolbar>
  );

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled />
        <BooleanInput source="active" label="Activo" disabled />
        <BooleanInput source="is_available_for_chat" label="Chat disponible" />
        <TextInput source="name" label="Nombre" disabled />
      </SimpleForm>
      <Confirm
        isOpen={isOpen}
        loading={false}
        title="Eliminar restaurante"
        content={"¿Seguro que deseas eliminar este restaurante?"}
        onConfirm={handleDeleteRestaurant}
        onClose={handleDeleteRestaurantDialogClose}
        confirm={"Eliminar"}
        cancel={"No"}
      />
    </Edit>
  );
};

export const RestaurantCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput
        source="customerID"
        label="Customer id"
        validate={validateCustomer}
      />
    </SimpleForm>
  </Create>
);
