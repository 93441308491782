import axios, { AxiosInstance } from "axios";

const apiURL = process.env.REACT_APP_API_URL;
const sendbirdApiURL = process.env.REACT_APP_SENDBIRD_API_URL;
const axiosInstance: AxiosInstance = axios.create({ baseURL: apiURL });
export const axiosSendbirdInstance: AxiosInstance = axios.create({
  baseURL: sendbirdApiURL,
});

export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    config.headers.Authorization = `${token}`;
    config.headers["access-control-allow-origin"] = "*";
    config.headers["Tenant"] = "admin";
    return config;
  });
};

axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
    }

    return Promise.reject(error.response.data);
  }
);

axiosSendbirdInstance.interceptors.request.use(
  async (request) => {
    if (request.headers) {
      request.headers["Content-Type"] = "application/json; charset=utf8";
      request.headers["Api-Token"] = process.env.REACT_APP_SENDBIRD_API_KEY;
    }
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
