import React, { useState } from "react";
import { useGetCatalog, useRemoveBulkProducts } from "../../services/products";
import {
  useRecordContext,
  List,
  Datagrid,
  ImageField,
  TextField,
  Pagination,
  ReferenceManyField,
  Confirm,
  DateField,
  EditButton,
} from "react-admin";
import { DataGrid as xDataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useDataProvider } from "react-admin";
import { Button } from "@material-ui/core";

const DownloadCsvButton = ({ supplierId, supplierName }) => {
  const dataProvider = useDataProvider();

  const handleDownload = (e) => {
    e.preventDefault();
    dataProvider.downloadCsvCatalog("supplier-catalog", {
      supplier_id: supplierId,
      supplier_name: supplierName,
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      size="auto"
    >
      Download CSV
    </Button>
  );
};

const ProductsFromCatalogTable = ({ tableCSV, tableCSVErrors, refresh }) => {
  const record = useRecordContext();
  const [supplierCatalog, setSupplierCatalog] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const { mutate: removeBulk, isLoading: isRemovingProducts } =
    useRemoveBulkProducts();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
    },
    {
      field: "code",
      headerName: "Código",
      flex: 1,
      editable: true,
    },
    {
      field: "unit",
      headerName: "Unidad",
      flex: 1,
      editable: true,
    },
    {
      field: "price",
      headerName: "Precio",
      type: "number",
      flex: 1,
      editable: true,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      editable: true,
    },
    {
      field: "stock",
      headerName: "Cantidad disponible",
      type: "number",
      flex: 1,
      editable: true,
    },
    {
      field: "brand",
      headerName: "Marca",
      flex: 1,
      editable: true,
    },
    {
      field: "product_category",
      headerName: "Categoría",
      flex: 1,
      editable: true,
    },
  ];

  const errorColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      valueGetter: (params) => params.row.csvData.name,
    },
    {
      field: "code",
      headerName: "Código",
      flex: 1,
      valueGetter: (params) => params.row.csvData.code,
    },
    {
      field: "unit",
      headerName: "Unidad",
      flex: 1,
      valueGetter: (params) => params.row.csvData.unit,
    },
    {
      field: "price",
      headerName: "Precio",
      type: "number",
      flex: 1,
      valueGetter: (params) => params.row.csvData.price,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      valueGetter: (params) => params.row.csvData.sku,
    },
    {
      field: "stock",
      headerName: "Cantidad disponible",
      type: "number",
      flex: 1,
      valueGetter: (params) => params.row.csvData.stock,
    },
    {
      field: "brand",
      headerName: "Marca",
      flex: 1,
      valueGetter: (params) => params.row.csvData.brand,
    },
    {
      field: "product_category",
      headerName: "Categoría",
      flex: 1,
      valueGetter: (params) => params.row.csvData.product_category,
    },
    {
      field: "error",
      headerName: "Error detectado",
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.error[0],
    },
  ];

  const handleCreateCatalogTable = (supplierCatalog) => {
    const catalog = supplierCatalog.data?.map((product) => ({
      name: product.name || "",
      code: product.code || "",
      unit: product.unit || "",
      price: product.price || "",
      sku: product.sku || "",
      stock: product.stock || "",
      brand: product.brand || "",
      product_category: product.product_category.name || "",
      available: product.available ? "Disponible" : "Disponible",
    }));
    setSupplierCatalog(catalog);
  };

  const { isLoading: isLoadingCatalog } = useGetCatalog(record.id, {
    onSuccess: handleCreateCatalogTable,
  });

  const handleBulkRemoveProducts = () => {
    if (selectedIds?.length) {
      removeBulk(
        { product_ids: selectedIds },
        {
          onSuccess: () => {
            toast.success("Productos borrados exitosamente");
            refresh();
          },
        }
      );
    }
  };

  const RemoveProductBulkButton = ({ label, selectedIds }) => {
    return (
      <button onClick={() => handleDeleteDialog(selectedIds)}>{label}</button>
    );
  };

  const ConnectBulkActionButtons = (props) => {
    return <RemoveProductBulkButton label="Remover productos" {...props} />;
  };

  const handleDeleteDialog = (selectedIds) => {
    setSelectedIds(selectedIds);
    setIsOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setSelectedIds([]);
    setIsOpen(false);
  };

  const _getCurrentCatalogTable = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <b>¿Tiene Catálogo Master?:</b>{" "}
          {record.has_official_catalog ? "Si" : "No"}
        </span>
        {Boolean(!supplierCatalog.length) && <span>No posee productos</span>}
        {Boolean(supplierCatalog.length) && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <ReferenceManyField
                reference="supplier-catalog"
                target="id"
                label="Órdenes recientes"
              >
                <Datagrid
                  empty={<p>No existen productos para este proveedor</p>}
                  bulkActionButtons={<ConnectBulkActionButtons />}
                >
                  <ImageField
                    source="product_thumbnail.thumb_url"
                    title="product_thumbnail"
                    label="Imagen"
                    sx={{
                      "& img": {
                        maxWidth: 40,
                        maxHeight: 40,
                        borderRadius: 1,
                        objectFit: "fill",
                      },
                    }}
                  />
                  <TextField source="name" label="Nombre" />
                  <TextField source="code" label="Código" />
                  <TextField source="unit" label="Unidad" />
                  <TextField source="price" label="Precio" />
                  <TextField source="sku" label="SKU" />
                  <TextField source="stock" label="Cantidad disponible" />
                  <TextField source="brand" label="Marca" />
                  <TextField source="product_category" label="Categoría" />
                  <DateField
                    label="Última actualización"
                    source="updated_at"
                    locales="es-ES"
                    options={{
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }}
                  />
                  <EditButton
                    label="Editar"
                    target="_blank"
                    resource="/products"
                  />
                </Datagrid>
                <Pagination />
              </ReferenceManyField>
              <Confirm
                isOpen={isOpen}
                loading={isRemovingProducts}
                title="Borrar productos"
                content={"¿Seguro que deseas eliminar estos productos?"}
                onConfirm={handleBulkRemoveProducts}
                onClose={handleDeleteDialogClose}
                confirm={"Eliminar"}
                cancel={"No"}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const _getTableCSV = () => {
    return (
      <div style={{ height: 400, width: "100%", display: "flex", flex: 1 }}>
        <div style={{ display: "flex", height: "100%", flex: 1 }}>
          <Box
            sx={{
              "& .MuiDataGrid-cell--editable": {
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#376331" : "#FFD2D2",
              },
            }}
            style={{ flexGrow: 1 }}
          >
            <xDataGrid
              rows={tableCSV}
              getRowId={(row) => row.sku + row.name}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowEditCommit={(edit) => {}}
              isCellEditable={(params) =>
                tableCSVErrors
                  .map((error) => error.csvData.sku)
                  .includes(params.row.sku)
              }
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </div>
      </div>
    );
  };

  const GetTitle = () => {
    const record = useRecordContext();

    if (!record) {
      return null;
    }

    return <span> - {record.name}</span>;
  };

  const _getTableCSVErrors = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div style={{ height: 400, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <Box
              sx={{
                "& .MuiDataGrid-cell--editable": {
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#376331" : "#FFD2D2",
                },
              }}
              style={{ flexGrow: 1 }}
            >
              <xDataGrid
                rows={tableCSVErrors}
                getRowId={(row) => row.sku + row.name}
                columns={errorColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                onRowEditCommit={(edit) => {}}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  };

  if (isLoadingCatalog) {
    return (
      <Box textAlign="center">
        <CircularProgress color="info" />
      </Box>
    );
  }

  if (tableCSV.length) {
    return (
      <>
        {Boolean(tableCSVErrors.length) && (
          <span>
            ERRORES DETECTADOS: Corrige los productos problemáticos y vuelve a
            subir el .csv
          </span>
        )}
        <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
          {_getTableCSV()}
          {Boolean(tableCSVErrors.length) && _getTableCSVErrors()}
        </div>
      </>
    );
  }

  return (
    <>
      <List title={<GetTitle />} pagination={false} actions={<></>}>
        {_getCurrentCatalogTable()}
      </List>
      <Box mt={2} mb={2}>
        <DownloadCsvButton supplierId={record.id} supplierName={record.name} />
      </Box>
    </>
  );
};

export default ProductsFromCatalogTable;
