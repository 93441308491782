import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { UseMutationResult } from "react-query";
import Endpoints from "../constants/Endpoints";
import axiosInstance from "./api";

interface UpdateHasMasterCatalogFormdata {
  has_official_catalog: boolean;
  id?: number;
}

interface CreateRestaurantsSuppliersFormdata {
  can_see_products_prices: boolean;
  chat_channel_url?: string;
  supplier_id: number;
  restaurant_id: number;
  contact_email?: string;
  contact_phone?: string;
}

interface UpdateRestaurantsSuppliersPoCFormdata {
  contact_phone: string;
  contact_email: string;
  contact_first_name: string;
  contact_last_name: string;
  supplier_id: number;
  restaurant_id: number;
}

interface DeleteRestaurantsSuppliersPoCFormdata {
  supplier_id: number;
  restaurant_id: number;
}

interface DeactivateSupplierFormData {
  supplier_id: number;
}

interface GetRestaurantsSupplierFormData {
  supplier_id: number;
}

interface DeleteSupplierFormData {
  supplier_id: number;
}

interface SupplierFile {
  id: number;
  upload_time: string;
  url: string;
  filename: string;
  supplier_id: number;
}

const _updateHasMasterCatalog = async (
  formData: UpdateHasMasterCatalogFormdata
): Promise<any> => {
  const supplierId = formData.id;
  delete formData.id;
  try {
    const response: any = (
      await axiosInstance.patch(
        `${Endpoints.SUPPLIER_UPDATE_HAS_MASTER_CATALOG}`.replace(
          "{id}",
          supplierId
        ),
        formData
      )
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useUpdateHasMasterCatalog = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (uploadMasterCatalogFormData) =>
      _updateHasMasterCatalog(uploadMasterCatalogFormData),
    options
  );
};

const _createRestaurantsSuppliers = async (
  formData: CreateRestaurantsSuppliersFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.post(`${Endpoints.RESTAURANTS_SUPPLIERS}`, formData)
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useCreateRestaurantsSuppliers = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (createRestaurantsSuppliersFormData) =>
      _createRestaurantsSuppliers(createRestaurantsSuppliersFormData),
    options
  );
};

const _updateRestaurantsSuppliers = async (
  formData: UpdateRestaurantsSuppliersPoCFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.patch(`${Endpoints.RESTAURANTS_SUPPLIERS}`, formData)
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useUpdateRestaurantsSuppliers = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (updateRestaurantsSuppliersFormData) =>
      _updateRestaurantsSuppliers(updateRestaurantsSuppliersFormData),
    options
  );
};

const _deleteRestaurantsSuppliers = async (
  formData: DeleteRestaurantsSuppliersPoCFormdata
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.delete(`${Endpoints.RESTAURANTS_SUPPLIERS}`, {
        params: formData,
        data: formData,
      })
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useDeleteRestaurantsSuppliers = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (deleteRestaurantsSuppliersFormData) =>
      _deleteRestaurantsSuppliers(deleteRestaurantsSuppliersFormData),
    options
  );
};

const _patchBulkRestaurantsSuppliers = async (
  formData: CreateRestaurantsSuppliersFormdata[]
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.patch(
        `${Endpoints.RESTAURANTS_SUPPLIERS}/bulk-update`,
        formData
      )
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const usePatchBulkRestaurantsSuppliers = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (patchBulkRestaurantsSuppliersFormData) =>
      _patchBulkRestaurantsSuppliers(patchBulkRestaurantsSuppliersFormData),
    options
  );
};

const _deactivateSupplier = async (
  formData: DeactivateSupplierFormData
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.patch(
        `${Endpoints.SUPPLIER_DEACTIVATE}`.replace("{id}", formData.supplier_id)
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useDeactivateSupplier = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (deactivateSupplierFormData: DeactivateSupplierFormData) =>
      _deactivateSupplier(deactivateSupplierFormData),
    options
  );
};

const _getRestaurantsSupplier = async (
  formData: DeactivateSupplierFormData
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.get(`${Endpoints.RESTAURANTS_SUPPLIERS}`, {
        params: {
          "filter.supplier_id": formData.supplier_id,
        },
      })
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useGetRestaurantsSupplier = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (getRestaurantsSupplierFormData: GetRestaurantsSupplierFormData) =>
      _getRestaurantsSupplier(getRestaurantsSupplierFormData),
    options
  );
};

const _deleteSupplier = async (
  formData: DeleteSupplierFormData
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.delete(
        `${Endpoints.SUPPLIER_DELETE}`.replace("{id}", formData.supplier_id)
      )
    ).data;
    return response;
  } catch (error) {
    return error;
  }
};

export const useDeleteSupplier = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (deleteSupplierFormData: DeleteSupplierFormData) =>
      _deleteSupplier(deleteSupplierFormData),
    options
  );
};

const _getSupplierFiles = async (
  supplierId: number
): Promise<SupplierFile[]> => {
  try {
    const response = await axiosInstance.get(
      `${Endpoints.GET_SUPPLIER_FILES}/${supplierId}`
    );
    return response.data.uploadedFiles;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useGetSupplierFiles = (
  supplierId: number,
  options?: UseQueryOptions<SupplierFile[]>
) => {
  return useQuery(
    ["supplierFiles", supplierId],
    () => _getSupplierFiles(supplierId),
    options
  );
};
