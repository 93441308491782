import { red } from "@material-ui/core/colors";

export const listoCustomTheme = {
    palette: {
        primary: {
            light: '#FF4C00',
            main: '#000',
            dark: '#FF4C00',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FF4C00',
            main: '#FF4C00',
            dark: '#FF4C00',
            contrastText: '#fff',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            styleOverrides: {
                root: { // Name of the rule
                    color: '#fff', // Some CSS
                    background: '#000',
                    borderRadius: 50,
                    marginRight: 5,
                    marginLeft: 5,
                    padding: '15px !important',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#FF4C00',
                    },
                },
                textPrimary: {
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#FF4C00',
                    }
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    backgroundColor: '#FF4C00',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: '#484848',
                    marginRight: 10,
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8,
                    fontWeight: 'bold',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#FF4C00',
                    },
                },
                textColorInherit: {
                    color: '#fff',
                }
            }
        },
        RaTabbedShowLayout: {
            styleOverrides: {
                content: {
                    minHeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }
            }
        },
        RaLogin: {
            styleOverrides: {
                main: {
                    backgroundImage: 'radial-gradient(circle at 50% 14em, #FF4C00A5 0%, #FF4D00 60%, #FF4D00 100%)',
                }
            }
        },
        RaShow: {
            styleOverrides: {
                main: {
                    height: '100%',
                    maxHeight: '100%'
                }
            }
        },
        // MuiTableHead: {
        //     root: {
        //         textAlignLast: 'center'
        //     }
        // }
    },
}
